import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import AboutWizySectionWrapper, { SocialLinks } from './aboutWizySection.style';

const AboutWizySection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  memberName,
  designation,
  contentStyle,
  description,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        teamMember {
          management {
            name
            designation
            id
            thumbnail_url {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            social_links {
              id
              icon
              url
            }
          }
          exec {
            name
            designation
            id
            thumbnail_url {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            social_links {
              id
              icon
              url
            }
          }
        }
      }
    }
  `);

  return (
    <AboutWizySectionWrapper id="teamSection">
      <Container>
        <Box {...sectionHeader}>
          <Heading content="Our Mission" {...sectionTitle} />
          <Text
            {...description}
            content="We support Frontline Workers by delivering  Innovative Digital Cloud Solutions that require no code, no training and are easily usable by any standard of literacy. We enable the Frontline Worker to leverage Android Enterprise, Google Hyperscale Cloud Platform and Artificial Intelligence services to digitize their everyday work. "
          />
        </Box>
        <Box {...sectionHeader}>
          <Heading content="Our Story" {...sectionTitle} />
          <Text
            {...description}
            content="Wizy is a team of entrepreneurs building B2B SaaS solutions for frontline work, on Google technologies. We work with innovative customers to develop disruptive Cloud SaaS solutions. We leverage the power of the Cloud, AI and Mobile adoption to disrupt and innovate."
          />

          <Text
            {...description}
            content="We are “global by design” with founders and teams on 4 continents, as well as customers in 30 countries. Our team of Developers, QA and Customer Success associates are located in Angers France, near the “Chateaux de la Loire”, and in Manila Philippines."
          />
          <Text
            {...description}
            content="Wizy was launched in September 2016 simultaneously in San Francisco, Manila and Paris by co-founders Laurent Gasser, Gino Tria and Louis Naugès. They combined their strong experiences in business, technology and markets. Louis Naugès and Laurent Gasser are repeat entrepreneurs who previously co-founded Revevol in 2007, the first GSuite partner in EMEA which brought to Google 70% of GSuite early adopters worldwide."
          />
          <Text
            {...description}
            content="Wizy is Google Cloud Platform Partner and Android Enterprise
            Partner"
          />
        </Box>
      </Container>
    </AboutWizySectionWrapper>
  );
};

// AboutWizySection style props
AboutWizySection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  memberName: PropTypes.object,
  designation: PropTypes.object,
};

// AboutWizySection default style
AboutWizySection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px'],
  },
  // sub section default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#10ac84',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    fontSize: ['26px', '26px', '30px', '40px'],
    padding: '0 80px',
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
    textAlign: 'center',
  },
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Team member col default style
  col: {
    width: [1, 1 / 2, 1 / 3, 1 / 3],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // Team member content default style
  contentStyle: {
    textAlign: 'center',
    mt: '25px',
  },
  // Team member memberName default style
  memberName: {
    fontSize: ['18px', '18px', '16px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Team member description default style
  designation: {
    fontSize: ['15px', '16px', '14px', '17px'],
    lineHeight: '1',
    color: 'rgba(15, 33, 55, 0.6)',
    mb: 0,
  },
};

export default AboutWizySection;
