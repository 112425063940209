import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import GalleryWrapper, { GalleryCard, Button } from './gallery.style';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';

const Gallery = ({ sectionHeader, sectionTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      agencyJson {
        customerList {
          id
          imageUrl {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const glideOptions = {
    type: 'carousel',
    perView: 5,
    autoplay: 2000,
    gap: 0,
    breakpoints: {
      1200: {
        perView: 4,
      },
      991: {
        perView: 3,
      },
      480: {
        perView: 2,
      },
    },
  };
  var companies = [];
  for (let count = 0; count < 27; count++) {
    companies.push({
      id: count,
      imageUrl: `../../assets/image/agency/companies/${count}.png`,
    });
  }
  console.log('companies', companies);
  return (
    <GalleryWrapper id="gallery">
      <Box {...sectionHeader}>
        <Heading
          content="Join our happy community of wizy.io SaaS users"
          {...sectionTitle}
        />
      </Box>
      <GlideCarousel
        carouselSelector="gallery_carousel"
        options={glideOptions}
        nextButton={<i className="flaticon-next" />}
        prevButton={<i className="flaticon-left-arrow" />}
      >
        <Fragment>
          {data.agencyJson.customerList.map((item) => {
            return (
              <GlideSlide>
                <GalleryCard>
                  <Image src={item.imageUrl.childImageSharp.fluid.src} />
                </GalleryCard>
              </GlideSlide>
            );
          })}
        </Fragment>
      </GlideCarousel>
    </GalleryWrapper>
  );
};
// TeamSection default style
Gallery.defaultProps = {
  // section header default style
  sectionHeader: {
    mt: ['25px', '40px'],
  },

  // section title default style
  sectionTitle: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
    textAlign: 'center',
  },
};
export default Gallery;
