import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Text from "reusecore/src/elements/Text";
import CopyrightWrapper from "./copyrightSection.style";

const CopyrightSection = () => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        social_profile {
          id
          icon
          link
        }
      }
    }
  `);

  return (
    <CopyrightWrapper className="copyright_section">
      <Text content="Copyright 2019 wizy.io" />
    </CopyrightWrapper>
  );
};

export default CopyrightSection;
